import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";

const Hero = lazy(() => import("./components/pages/Hero"));
const Login = lazy(() => import("./components/pages/Login"));
const CreateAccount = lazy(() => import("./components/pages/CreateAccount"));
const ResetPassword = lazy(() => import("./components/pages/ResetPassword"));

const App: React.FC = () => {
  return (
    <Router>
      <Helmet>
        <title>Armada Digital Agency</title>
        <link rel="icon" href="/api/placeholder/32/32" type="image/x-icon" />
        <meta
          name="description"
          content="Armada Digital Agency - Elevate Your Digital Presence"
        />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
